
import { Component, Prop, Vue } from "vue-property-decorator"

@Component
export default class Printable extends Vue {
  @Prop({ default: "Peralta Document" }) readonly title!: string
  @Prop() readonly footer!: boolean
  @Prop() readonly address?: string
  @Prop({ default: "internal" }) readonly printStrategy?: "internal" | "external"
  @Prop({ default: "center" }) readonly contentAlignment!: string

  @Prop({ default: () => ({}) }) readonly display?: {
    large: boolean
    alignPrintPage: "left" | "right"
  }

  showPrintConfirm = false

  onPrint() {
    this.showPrintConfirm = false

    const prtHtml = (this.$refs.printPage as unknown as Element).outerHTML // Get HTML to print from element

    if (this.printStrategy == "external") {
      let stylesHtml = ""
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML
      } // Get all stylesheets HTML

      let printContents = `<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body onload="javascript:window.alert('go')">
          <div class="v-application v-application--is-ltr theme--light">
            ${prtHtml}
          </div>
          </body>
        </html>`
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      )

      if (WinPrint) {
        // write print content to window
        WinPrint.document.write(printContents)
        WinPrint.document.close() // finishes writing to a document

        WinPrint.document.title = this.title
        WinPrint.focus()

        WinPrint.onload = () => {
          if (WinPrint.document.readyState === "complete") {
            // wait for document to finish loading
            WinPrint.print()

            setTimeout(() => {
              WinPrint.close() // close window after 100ms, window closes after print dialog closes
            }, 100)
          }
        }

        WinPrint.onafterprint = function () {
          WinPrint.close() // attempt to close after print
        }
      }
    } else {
      let printContents = `<div class="v-application v-application--is-ltr theme--light printable-container-wrap">
            ${prtHtml}
          </div>`
      const originalDocumentTitle = document.title

      document.title = this.title
      document.body.classList.add("printing")
      document.body.insertAdjacentHTML("beforeend", printContents)

      window.setTimeout(() => {
        // we wait a bit for browser to repaint then print
        window.print()

        document.title = originalDocumentTitle
        document.body.classList.remove("printing")
        let printable = document.querySelector(".printable-container-wrap")
        printable && document.body.removeChild(printable)
      }, 100)
    }
  }
  // see paged.js, snipme function
}
